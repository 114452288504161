import React from "react";
import images from "../../../assets/image-base";
// import "./index.scss";
import { Form } from "antd";
import InputComponent from "../../../components/FormComponent/Input";
import ButtonBase from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { useValidationRules } from "../../../constants/rules";
import { useNavigate } from "react-router-dom";

const ForgotPasswordContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { MailRules } = useValidationRules();
  const onSubmitSendMail = () => {
    navigate("/auth/change-password");
  };
  return (
    <div className="bg-[#999999] min-h-screen">
      <div className="pl-10 py-[14px]">
        <img src={images.common.companyLogo} width={215} alt="logo" />
      </div>
      <div className="flex justify-around">
        <div className="text-[#999999]">abc</div>
        <div className="bg-white rounded w-[375px] h-full mt-10 p-6">
          <div className="text-xl font-bold text-[#ff7421] mb-[16px]">
            {t("auth.restPassword")}
          </div>
          <div className="header-modal-login"></div>
          <Form className="mt-6" onFinish={onSubmitSendMail}>
            <Form.Item name="email" rules={MailRules}>
              <InputComponent
                title={t("auth.email")}
                placeholder={t("auth.pleaseInputEmail")}
                required
              />
            </Form.Item>
            <div className="text-[11px] text-[#655f5f] mt-[-20px]">
              {t("auth.noteForInputMail")}
            </div>

            <ButtonBase
              title={t("auth.sendMail")}
              htmlType="submit"
              className="w-[327px] h-11 mt-4"
            />
          </Form>
          <div className="header-modal-login mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
