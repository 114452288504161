import React from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";

const MenuBar = () => {
  const { t } = useTranslation();
  const menuItem = [
    {
      key: 1,
      label: t("common.accountList"),
      path: "/setting",
    },
    {
      key: 2,
      label: t("common.listOfConcept"),
      path: "/setting",
    },
    {
      key: 3,
      label: t("common.listCompany"),
      path: "/setting",
    },
    {
      key: 4,
      label: t("common.implementation"),
      path: "/setting",
    },
  ];
  return <Menu items={menuItem} />;
};
export default MenuBar;
