import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_DOMAIN,
  prepareHeaders: (headers, { getState }) => {
    // Lấy token từ localStorage hoặc Redux state
    const token = "5|i5Fb7Hq9XJvWRxJ1DtlXV9ygrcj3ZxxNWxqW5cYg4fcaad16";
    headers.set('Content-Type', 'application/json');
    // Thêm token vào header Authorization nếu có
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    
    return headers;
  },
});

// Hàm baseQueryWithErrorHandling để xử lý lỗi
const baseQueryWithErrorHandling = async (args, api, extraOptions) => { 
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      // Xử lý lỗi 401 (Unauthorized)
      console.error('Unauthorized! Redirecting to login...');
    } else if (result.error.status === 500) {
      // Xử lý lỗi 500 (Internal Server Error)
      console.error('Server Error! Please try again later.');
    }
  }

  return result; // Trả về kết quả (thành công hoặc lỗi)
};

export default baseQueryWithErrorHandling;