import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import someReducer from "./someReducer";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { apiPositionSlice } from "./Settings/Positions";
import { apiDepartmentSlice } from "./Settings/Departments";
import { apiLoginSlice } from "./Auth/Login";
const persistConfig = {
  key: "root",
  storage,
};
const apiSlices = [apiPositionSlice, apiDepartmentSlice, apiLoginSlice]; // Mảng chứa tất cả các slices
const rootReducer = combineReducers({
  someState: someReducer,
  [apiPositionSlice.reducerPath]: apiPositionSlice.reducer,
  [apiDepartmentSlice.reducerPath]: apiDepartmentSlice.reducer,
  [apiLoginSlice.reducerPath]: apiLoginSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Tạo store với reducer đã được persist
export const store = configureStore({
  reducer: persistedReducer, // Truyền persistedReducer vào store
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...apiSlices.map((slice) => slice.middleware)),
});

// Tạo persistor để lưu trữ state
export const persistor = persistStore(store);
