import React, { Suspense, useState, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "antd/dist/reset.css";
import "./locales/i18n";
import { Provider } from "react-redux";
import { persistor, store } from "../src/store/index"; // Import store
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ConfigProvider } from "antd";

export const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));
const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

document.title = process.env.REACT_APP_TITLE || "GPT-BrandBook";
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CustomRouter history={history}>
          <Suspense fallback={null}>
            <ConfigProvider
              theme={{
                token: {
                  fontFamily: "Noto Sans JP , sans-serif",
                },
              }}
            >
              <App />
            </ConfigProvider>
          </Suspense>
        </CustomRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
