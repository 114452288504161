import React from "react";
import { Table } from "antd";
import "./index.scss";

const TableBaseComponent = (props) => {
  const { columns, dataSource, expandable, ...otherProps } = props;
  return (
    <Table
      className="table-component"
      columns={columns}
      expandable={expandable}
      dataSource={dataSource}
      {...otherProps}
    />
  );
};
export default TableBaseComponent;
