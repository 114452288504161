import React, { useState } from "react";
import HeaderContent from "../../../components/HeaderContent/index";
import ButtonBase from "../../../components/Button/index";
import CreateEditDepartment from "./CreateEditDepartment";
import TableBaseComponent from "../../../components/FormComponent/Table";
import {
  useGetListDepartmentsQuery,
  useDeleteDepartmentMutation,
} from "../../../store/Settings/Departments";
import { useTranslation } from "react-i18next";

const ListDepartments = () => {
  const { t } = useTranslation();
  const [modalCreate, setModalCreate] = useState({
    data: {},
    isOpen: false,
  });
  const { data: listDepartments } = useGetListDepartmentsQuery(undefined, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [deleteDepartment] = useDeleteDepartmentMutation();

  const onDeleteDepartment = async (record) => {
    try {
      await deleteDepartment(record.id).unwrap();
    } catch (error) {}
  };
  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: t("setting.title"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("setting.detail"),
      key: "description",
      dataIndex: "description",
    },
    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[#ff7421] cursor-pointer">
          <div
            className="mr-5"
            onClick={() => setModalCreate({ data: record, isOpen: true })}
          >
            {t("setting.edit")}
          </div>
          <div onClick={() => onDeleteDepartment(record)}>
            {t("setting.delete")}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("setting.industry")}
          className="ml-3"
        />
        <div className="flex items-center pr-5">
          <ButtonBase
            className="w-[108px] h-[36px]"
            title={t("setting.newCreate")}
            onClick={() => setModalCreate({ isOpen: true })}
          />
        </div>
      </div>
      <div className="p-4">
        <TableBaseComponent
          columns={columns}
          dataSource={listDepartments.data?.data}
        />
      </div>
      {modalCreate.isOpen && (
        <CreateEditDepartment
          modalCreate={modalCreate}
          setModalCreate={setModalCreate}
        />
      )}
    </div>
  );
};
export default ListDepartments;
