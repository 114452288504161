import { Select } from "antd";
import React from "react";

const SelectComponent = (props) => {
  const { className, title, required, ...otherProps } = props;
  return (
    <div>
      <div className="font-semibold">
        {title}
        {required && <span className="text-[#ff0000]">*</span>}
      </div>
      <Select
        className={`${className} h-10 hover:!border-[#ff7421] focus:!border-[#ff7421]`}
        {...otherProps}
      />
    </div>
  );
};
export default SelectComponent;
