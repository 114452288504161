import { Col, Form, Modal, Row } from "antd";
import React from "react";
import InputComponent from "../../../components/FormComponent/Input";
import SelectComponent from "../../../components/FormComponent/Select";
import "./index.scss";
import ButtonBase from "../../../components/Button";
import {
  useCreatePositionMutation,
  useUpdatePositionMutation,
} from "../../../store/Settings/Positions";
import { useTranslation } from "react-i18next";

const CreateEditPositon = (props) => {
  const { t } = useTranslation();
  const { modalCreate, setModalCreate, handleSubmit, listDepartments } = props;
  const [createPosition, { isLoading }] = useCreatePositionMutation();
  const [updatePosition, { isLoadingUpdate }] = useUpdatePositionMutation();
  const onSubmitPosition = async (values) => {
    try {
      if (modalCreate.data) {
        await updatePosition({
          id: modalCreate.data.id,
          data: values,
        }).unwrap();
      } else {
        await createPosition(values).unwrap();
      }
      setModalCreate({ data: {}, isOpen: false });
    } catch (error) {
      console.log(error);
    }
  };

  const listOptions = listDepartments.map((item) => {
    return { value: item.id, label: item.name };
  });

  return (
    <Modal
      title={!modalCreate.data ? t("setting.create") : t("setting.edit")}
      open={modalCreate.isOpen}
      footer={false}
      onCancel={() => setModalCreate({ data: {}, isOpen: false })}
      onOk={handleSubmit}
    >
      <Form
        className="form-create-position"
        onFinish={onSubmitPosition}
        initialValues={modalCreate.data}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item name="name">
              <InputComponent title={t("setting.title")} required />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description">
              <InputComponent title={t("setting.detail")}></InputComponent>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="department_id">
              <SelectComponent
                options={listOptions}
                mode="multiple"
                defaultValue={modalCreate.data?.departments.map(
                  (item) => item.id
                )}
                title={t("setting.department")}
                required
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-center mt-4">
          <ButtonBase
            title={t("common.submit")}
            htmlType="submit"
            disabled={isLoading || isLoadingUpdate}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CreateEditPositon;
