import React from "react";
import "./index.scss";
const HeaderContent = (props) => {
  const { icon, textPreviousPage, textCurrentPage, actionBack, className } =
    props;
  return (
    <div className={`${className} bg-white header-content`}>
      {textPreviousPage && (
        <div className="flex" onClick={actionBack}>
          <div>{icon}</div>
          <div className="ml-3">{textPreviousPage}</div>
        </div>
      )}
      <div className="text-xl font-bold">{textCurrentPage}</div>
    </div>
  );
};
export default HeaderContent;
