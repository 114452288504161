import { Button } from "antd";
import React from "react";

const ButtonBase = (props) => {
  const { title, className, onClick, ...otherProps } = props;
  return (
    <Button
      className={`${className} button-base bg-[#ff7421] text-white px-7 py-[10px] hover:!border-[#ff7421] hover:!text-[#ff7421]`}
      {...otherProps}
      onClick={onClick}
    >
      <div>{title}</div>
    </Button>
  );
};
export default ButtonBase;
