import { Layout } from "antd";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import SettingPosition from "./pages/Settings/Positions/index";
import SettingDepartment from "./pages/Settings/Department/index";
import MenuBar from "./components/SideBar/MenuBar";
import LoginScreen from "./pages/Auth/Login";
import ForgotPasswordScreen from "./pages/Auth/ForgotPassword";
import ChangePasswordScreen from "./pages/Auth/ChangePassword";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import images from "../src/assets/image-base";
const { Header, Content, Sider } = Layout;

function App() {
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");
  useEffect(() => {
    if (accessToken) {
      navigate("/setting/position");
    } else {
      navigate("/auth/login");
    }
  }, [accessToken, navigate]);

  return (
    <Layout>
      {accessToken ? (
        <>
          <Header className="bg-white header-app px-10 py-[14px] h-full">
            <img src={images.common.companyLogo} width={161} alt="logo" />
          </Header>
          <Layout hasSider={true} className="min-h-screen">
            <Sider className="!bg-white menu-bar">
              <MenuBar />
            </Sider>
            <Content className="!bg-[#fbfbfb]">
              <Routes>
                <Route path="/setting/position" element={<SettingPosition />} />
                <Route
                  path="/setting/department"
                  element={<SettingDepartment />}
                />
                <Route path="*" element={<Navigate to="/setting/position" />} />
              </Routes>
            </Content>
          </Layout>
        </>
      ) : (
        <Routes>
          <Route path="/auth/login" element={<LoginScreen />} />
          <Route
            path="/auth/forgot-password"
            element={<ForgotPasswordScreen />}
          />
          <Route
            path="/auth/change-password"
            element={<ChangePasswordScreen />}
          />
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      )}
    </Layout>
  );
}

export default App;
